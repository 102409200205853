<template>
  <div class="page-wrapper">
    <MoleculePageHeader
      :title="$t('pages.favorites.title')"
      align="left"
    >
      <div v-if="items.length && !this.loading">
        <MoleculeShare
          :numbers="numbers"
          :text="$t('presentation.download')"
        />
      </div>
    </MoleculePageHeader>
    <div class="page-body">
      <div class="container-xl">
        <div class="row row-cards">
          <template v-if="loading">
            <div
              v-for="n in 4"
              :key="n"
              class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3"
            >
              <MoleculeCard class="placeholder-glow">
                <template #header>
                  <div class="placeholder col-9 my-2"></div>
                </template>
                <div class="ratio ratio-3x4 card-img-top placeholder mb-3"></div>
                <div class="placeholder placeholder-xs col-9"></div>
                <div class="placeholder placeholder-xs col-7"></div>
                <div class="row align-items-center mt-3">
                  <div class="col-7">
                    <div class="placeholder placeholder-xs col-9"></div>
                    <div class="placeholder placeholder-xs col-7"></div>
                    <div class="placeholder placeholder-xs col-8"></div>
                  </div>
                  <div class="col-5 ms-auto text-end">
                    <div class="placeholder placeholder-xs col-8"></div>
                    <div class="placeholder placeholder-xs col-10"></div>
                    <div class="placeholder placeholder-xs col-9"></div>
                  </div>
                </div>
              </MoleculeCard>
            </div>
          </template>
          <template v-else-if="items.length">
            <div
              v-for="property in items"
              :key="property.id"
              class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3"
            >
              <MoleculeCard>
                <template #header>
                  <div class="d-flex align-items-center">
                    <h3 class="card-title">{{ property.title }}</h3>
                    <AtomStatus
                      v-if="property.status"
                      class="ms-3"
                      :color="property.status === 'AVAILABLE' ? 'success' : null"
                      variant="outline"
                    >
                      {{ $t(`property.status.${property.status}`) }}
                    </AtomStatus>
                  </div>
                </template>
                <MoleculeProperty
                  :data="property"
                  :is-favorite="property.favorite"
                  :is-favorite-clicked="clicked === property.id"
                  @clickFavorite="removeFavoriteHandler"
                />
              </MoleculeCard>
            </div>
          </template>
          <template v-else>
            <MoleculeEmpty
              icon="heart-broken"
              :title="$t('favorite.error.empty.title')"
              :message="$t('favorite.error.empty.message')"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import MoleculePageHeader from '@/components/MoleculePageHeader'
import MoleculeCard from '@/components/MoleculeCard'
import MoleculeProperty from '@/components/MoleculeProperty'
import MoleculeEmpty from '@/components/MoleculeEmpty'
import MoleculeShare from '@/components/MoleculeShare'
import AtomStatus from '@/components/AtomStatus'

export default {
  title: vm => vm.$t('pages.favorites.title'),

  components: {
    MoleculePageHeader,
    MoleculeCard,
    MoleculeProperty,
    MoleculeEmpty,
    MoleculeShare,
    AtomStatus,
  },

  data: () => ({
    loading: true,
    items: [],
    clicked: null,
  }),

  computed: {
    ...mapGetters(['favorites']),

    numbers () {
      return this.items.reduce((accumulator, property) => {
        if (!property.removed) {
          accumulator.push(property.number)
        }

        return accumulator
      }, [])
    },
  },

  async mounted () {
    try {
      await this.fetchFavorites()

      this.favorites.forEach(async item => {
        const property = await this.getProperty(item.id)
        property.favorite = true

        this.items.push(property)
      })
    } finally {
      setTimeout(() => { this.loading = false }, 1500)
    }
  },

  methods: {
    ...mapActions(['fetchFavorites', 'getProperty', 'createFavorite', 'removeFavorite']),

    async removeFavoriteHandler (flat) {
      if (!flat || !flat.id || !flat.house_id) { return }

      this.clicked = flat.id
      const index = this.items.findIndex(item => item.id === flat.id)
      const item = this.items[index]
      if (flat.removed) {
        await this.createFavorite({ id: flat.id, group: flat.house_id })
        if (index !== -1) {
          item.removed = false
          item.favorite = true
        }
      } else {
        await this.removeFavorite({ id: flat.id })
        if (index !== -1) {
          item.removed = true
          item.favorite = false
        }
      }
      this.$set(this.items, index, item)
      this.clicked = null
    },
  },
}
</script>
